.container {
  table {
    background: #FFFFFF;
    box-shadow: 4px 10px 20px rgba(55, 114, 255, 0.1);
    border-radius: 4px;
  }
  thead {
    th {
      font-family: Montserrat, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
      color: #011638;
      height: 56px;
      cursor: pointer;
      &:hover {
        & > div > div {
          visibility: visible;
        }
      }
      & > div {
        display: inline-flex;
        align-items: center;
        & > div {
          visibility: hidden;
        }
        svg {
          margin-left: 5px;
        }
      }
    }
  }
  td, th {
    padding: 0 16px;
  }
  tr {
    height: 56px;
    &.test td  {
      color: #E20338;
    }
    &.errorMessage * {
      color: #E20338;
      border-bottom: 1px solid #F1F2F2;
    }
  }

  td {
    font-size: 16px;
    color: #6D6E71;
  }
}
