/* Reset
/* ----------------------------------- */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

html {
    box-sizing: border-box;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    text-decoration: none;
    cursor: pointer;
}

blockquote cite a {
    font-weight: normal;
}

a:active,
a:hover {
    outline: 0;
    text-decoration: none;
}

b,
strong {
    font-weight: bold;
}

i,
em,
dfn {
    font-style: italic;
}

h1 {
    margin: 0;
    font-size: 5rem;
    font-weight: 700;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

svg:not(:root) {
    overflow: hidden;
}

mark {
    background-color: #fdffb6;
}

code,
kbd,
pre,
samp {
    font-family: monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0; /* 3 */
    color: inherit; /* 1 */
    font: inherit; /* 2 */
}

button {
    overflow: visible;
    border: none;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
    /* 1 */
input[type="reset"],
input[type="submit"] {
    cursor: pointer; /* 3 */
    -webkit-appearance: button; /* 2 */
}

button[disabled],
html input[disabled] {
    cursor: default;
}

input {
    line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input:focus {
    outline: none;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
}

input[type='search'] {
    box-sizing: content-box; /* 2 */
    -webkit-appearance: textfield; /* 1 */
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

legend {
    padding: 0; /* 2 */
    border: 0; /* 1 */
}

textarea {
    overflow: auto;
    resize: vertical;
}

td,
th {
    padding: 0;
}

/* Base Styles
/* ----------------------------------- */

body {
    font-family:
            -apple-system,
            BlinkMacSystemFont,
            'Segoe UI',
            Roboto,
            Helvetica,
            Arial,
            sans-serif,
            'Apple Color Emoji',
            'Segoe UI Emoji',
            'Segoe UI Symbol';
    font-size: 16px;
    font-weight: 200;
    line-height: 1.6em;
    font-style: normal;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga' on;
    font-feature-settings: 'liga' on;
}

hr {
    position: relative;
    display: block;
    width: 100%;
    margin: 1em 0;
    padding: 0;
    height: 1px;
    border: 0;
    background-color: #ccc;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

p,
ul,
ol,
dl,
blockquote {
    margin: 0;
}

ul {
    list-style: none;
}

ol {
    list-style: decimal;
}

ol ol,
ul ul,
ul ol,
ol ul {
    margin: 0;
}

li {
    margin: 0;
}

dt {
    margin: 0;
    padding: 0;
}

dd {
    margin: 0;
    padding: 0;
}

blockquote {
    margin: 1.5em 0;
    padding: 0 1.6em 0 1.6em;
}

blockquote p {
    margin: 0.8em 0;
    font-size: 1.2em;
    font-weight: 300;
}

blockquote small {
    display: inline-block;
    margin: 0.8em 0 0.8em 1.5em;
    font-size: 0.9em;
    opacity: 0.8;
}

/* Quotation marks */
blockquote small::before {
    content: '\2014 \00A0';
}

blockquote cite {
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    line-height: 1.15;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@media (max-width: 500px) {
    h1 {
        font-size: 2.2rem;
    }
}

h2 {
    margin: 0;
    font-size: 2rem;
}

@media (max-width: 500px) {
    h2 {
        font-size: 1.8rem;
    }
}

h3 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 500;
}

@media (max-width: 500px) {
    h3 {
        font-size: 1.7rem;
    }
}

h4 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 500;
}

h5 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
}

h6 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
}

.hide {
    display: none;
}
.MuiPickersYear-yearSelected {
    background-color: #3772FF !important;
}

div {
    font-family: Montserrat, serif;
}

.redux-toastr .toastr.rrt-error {
    background-color: #E20338 !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
