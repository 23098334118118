.number-input-container {
  background: #F2F8FD;
  height: 56px;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  &:hover {
    background: #E7EDFD !important;
  }
  .currency-input {
    position: absolute;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: none;
    padding: 0px 14px;
    height: 56px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    color: rgb(109, 110, 113);
    font-size: 16px;
    transition: border-bottom 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    &:focus {
      border-bottom: 2px solid rgb(55, 114, 255);
    }
  }
  &.error {
    .currency-input {
      &:focus {
        border-bottom: 2px solid rgb(226, 3, 56);
      }
    }
  }

  .label {
    font-size: 12px;
    position: absolute;
    line-height: initial;
    color: #6D6E71;
    left: 16px;
  }

  .helper-text {
    font-size: 12px;
    color: #6D6E71;
    margin-top: 0;
    position: absolute;
    line-height: 1.66;
    left: 14px;
    bottom: -19px;
    &.error {
      color: rgb(226, 3, 56);
    }
  }
}

