.terms-content {
  &::-webkit-scrollbar-track {
    background-color: green;
  }

  &::-webkit-scrollbar-thumb {
    background-color: red;
  }
}
body::-webkit-scrollbar-track {
  background-color: green;
}

body::-webkit-scrollbar-thumb {
  background-color: red;
}
