.MuiTextField-root {
  .MuiFilledInput-underline:before {
    border: none;
  }
  .MuiFilledInput-underline:hover:before {
    border-bottom: none !important;
  }
  .MuiFilledInput-underline.Mui-disabled:before {
    border-bottom: none !important;
  }
  .MuiFilledInput-root {
    background: #F2F8FD !important;
    &:hover {
      border: none;
      background: #E7EDFD !important;
    }
  }
  .MuiFilledInput-root.Mui-disabled:hover {
    background: #F2F8FD !important;
  }
  .MuiFormHelperText-root {
    font-size: 12px;
    color: #6D6E71;
    margin-top: 0;
  }
}
