.container {
  height: 56px;
  background: #F2F8FD;
  display: flex;
  padding: 0 16px;
  align-items: center;
  & > .StripeElement {
    width: 100%;

  }
  &:hover {
    background: #E7EDFD;
  }
  &.focused {
    border-bottom: 2px solid #3772FF;
  }
  &.error {
    border-bottom: 2px solid #E20338;
  }
}
